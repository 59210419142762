import { Address } from "./Address";

export class User
{
	UserId?: string;
	Email?:string;
	Password?:string;
	DisplayName?:string;
	PhotoURL?:string;
	Phone?: string;
	Address?: string;
	Address2?: string;
	City?: string;
	State?: string;
	Zip?: string;
	key?:string;
	ShippingAddress?:string;
	ShippingAddress2?:string;
	ShippingCity?: string;
	ShippingState?: string;
	ShippingZip?: string;
	UserType?:number;
	Photo?:string;
	Website?:string
	OrderNumber?:number;
	ForgotPassword?:boolean;
	PushNotification?:boolean;
	StateTax?:boolean;
	CompanyName?: string;
 
	constructor( ) { 
	 
	 }  
 
	

} 