
/* import { OrderBase } from "./OrderBase"; */

export class Cart  
{
	ItemNumber:number;
	Product_id?: string;
	Name?: string;
	Description?: string;
	Thumb?: any;
	Count?: number;
	UnitPrice?: string;
	TotalPrice?:number;
} 